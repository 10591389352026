import http from './http'

// 开票企业列表
export const invoiceCompanyList = (params) => http.post('/admin/InvoiceCompany/list', params || {})
// 开票企业新增
export const invoiceCompanyAdd = (params) => http.post('/admin/InvoiceCompany/create', params)
// 开票企业修改
export const invoiceCompanyUpdate = (id, params) => http.post(`/admin/InvoiceCompany/create${id}`, params)

// 详情编辑
export const edit = (id, params) => !!id ? invoiceCompanyUpdate(id, params) : invoiceCompanyAdd(params)


