<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="姓名" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="所属单位" prop="company_name">
          <el-input clearable v-model="table.params.company_name" placeholder="请输入所属单位"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:type="{row}">
        <span>{{ roleTypeObj[row.type] }}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>修改</el-button>
        <el-button type="text" @click='remove(row)'>删除</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { adminList } from '@/api/common.js'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    Edit,
    VTable
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      roleTypeObj: this.$store.state.roleTypeObj, //获取角色
      field: [
        { name: "id", label: "ID", width:"50"},
        { name: "type", label: "角色身份", hidden: false },
        { name: "name", label: "姓名", hidden: false },
        { name: "account", label: "账号", hidden: false},
        { name: "invoice_company_name", label: "所属单位", hidden: false},
        { name: "created_at", label: "创建时间", hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          company_name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      adminList(this.table.params).then(res => {
        this.table.data = res.data.list;
        this.table.total = res.data.total;
      })
    },
    showEdit(row) {
      console.log(!! row.id);
      // debugger
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/admin/delete', {id: row.id}).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！');
                this.getTable();
              } else {
                this.$message.error(res.msg)
              }
            })
            done()
          } else {
            done()
          }
        }
      })
    },
  }
}
</script>