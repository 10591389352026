<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
      :size="subUnitSize"
    >
      <el-form-item prop="name" label="姓名：">
        <el-input
          v-model="form.data.name"
          clearable
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="account" label="账号：">
        <el-input
          v-model="form.data.account"
          clearable
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item :prop="isChange ? '11' : 'password'" label="密码：">
        <el-input
          v-model="form.data.password"
          clearable
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item prop="invoice_company_id" label="所属单位">
        <el-select
          v-model="form.data.invoice_company_id"
          placeholder="请选择所属单位"
          clearable
          filterable
        >
          <el-option
            v-for="item in invoiceCompanyOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="type" label="角色">
          <el-radio :label="1" v-model="form.data.type"  @change="handleRadio(form.data.type)">管理员</el-radio>
          <el-radio :label="2" v-model="form.data.type" @change="handleRadio(form.data.type)">员工</el-radio>
      </el-form-item>
      <el-form-item prop="qx" label="权限">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox v-for="item in powerArr" :label="item.value" :key="item.value">{{
            item.label
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { invoiceCompanyList } from '@/api/finace.js'
  import common from '@/util'
  import {getStorage} from '@/storage'
  const powerOpt = [{label:'企业管理',value:'company'},{label:'保险管理',value:'insurance'},{label:'员工管理',value:'employee'},{label:'财务管理',value:'finance'}];
  export default {
    name: 'SystemUnitEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            account: '',
            password: '',
            phone: '',
            invoice_company_id: '',
            access: {
              company: 0,
              insurance: 0,
              employee: 0,
              finance: 0
            }
          },
          rules: {
            name: [{required: true, message: "请输入姓名！", trigger: "change"}],
            account: [{required: true, message: "请输入账号！", trigger: "change"}],
            password: [{required: true, message: "请输入密码！", trigger: "change"}],
            invoice_company_id: [{required: true, message: "请选择所属单位！", trigger: "change"}],
            type: [{required: true, message: "请选择角色！", trigger: "change"}],
          }
        },
        invoiceCompanyOpt: [],
        powerArr: powerOpt,
        isIndeterminate: false,
        checkAll: false,
        checkedCities: [],
      }
    },
    created() {
      this.getBase();
    },
    methods: {
      // 获取开票单位列表
      getBase() {
        let _icId = Number(getStorage('icId'))
        console.log(_icId);
        invoiceCompanyList().then(res => {
          this.invoiceCompanyOpt = res.data.list;
          if(_icId == 0) {
            let str = {
              id: 0,
              name: '总部'
            }
            this.invoiceCompanyOpt.unshift(str)
          }
        })
      },
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
        console.log(this.form.data.access);
        let accessObj = this.form.data.access
        this.checkedCities = [];
        for(let i in accessObj) {
          if(accessObj[i] === 1) {
            this.checkedCities.push(i)
          }
        }
       console.log(this.checkedCities.length,this.powerArr.length)
       if(this.checkedCities.length === this.powerArr.length) {
         this.checkAll = true
         this.isIndeterminate = false;
       } else {
         this.checkAll = false
         this.isIndeterminate = true;
       }
       
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data = {
          name: '',
          account: '',
          password: '',
          phone: '',
          invoice_company_id: '',
          access: {
            company: 0,
            insurance: 0,
            employee: 0,
            finance: 0
          }
        }
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let apiUrl = !this.isChange ? '/admin/admin/create' : '/admin/admin/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      // 选择角色
      handleRadio(type) {
        console.log(type);
        console.log(this.form.data.type);
        this.isIndeterminate = false;
        this.checkAll = type === 1 ? true : false;

        let access = this.form.data.access;
        if(type === 1) {
          this.powerArr.forEach(v => {
            this.checkedCities.push(v.value)
          })
          Object.keys(access).forEach(item =>{
            this.form.data.access[item] = 1;
          })
        } else {
          this.checkedCities = []
          Object.keys(access).forEach(item =>{
            this.form.data.access[item] = 0;
          })
        }
      },
      // 是否全选
      handleCheckAllChange(val) {
        console.log(val);
        let access = this.form.data.access;
        if(val === true) {
          this.powerArr.forEach(v => {
            this.checkedCities.push(v.value)
          })
          Object.keys(access).forEach(item =>{
            this.form.data.access[item] = 1;
          })
        } else {
          this.checkedCities = []
          Object.keys(access).forEach(item =>{
            this.form.data.access[item] = 0;
          })
        }
        this.isIndeterminate = false;
        // this.checkAll = val === 1 ? true : false;
      },
      handleCheckedCitiesChange(value) {
        console.log(value);
        let access = this.form.data.access;
        Object.keys(access).forEach(item =>{
          this.form.data.access[item] = 0;
        })
        value.forEach(item =>{
          this.form.data.access[item] = 1;
        })
        this.checkedCities = value
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.powerArr.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.powerArr.length;
      }
    }
  }
</script>
