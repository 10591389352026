import http from "./http";

// 账号列表
export const adminList = (params) => http.post('/admin/admin/list', params)
// 创建账号
export const adminAdd = (params) => http.post('/admin/admin/create', params)
// 修改账号
export const adminUpdate = (params) => http.post(`/admin/admin/edit`, params)

// 上传图片
export const uploadImg = (params) => http.post('common/qiniu/upload', params, {headers:{'Content-Type':'application/json'}})

